<template>
  <div class="pay">
    <BaseHeaderBar
      title="Kode pembayaran"
      :showCustomerService="false"
      :showBackButton="true"
    />
    <div class="content">
      <div class="pay-amount">
        <p class="amount">
          Rp{{ formatNumberWithCommas(payInfo.padryAmount) }}
        </p>
        <p class="label">Jumlah pembayaran</p>
      </div>
      <div class="pay-info-list">
        <div>
          <p class="label">Nama Bank</p>
          <p class="value">{{ payInfo.padryChannel }}</p>
        </div>
        <div>
          <p class="label">Metode pembayaran</p>
          <p class="value">{{ payInfo.padryWay }}</p>
        </div>
        <div>
          <p class="label">Masa berlaku</p>
          <p class="value">{{ payInfo.lodrseTime }}</p>
        </div>
        <div>
          <p class="label pembayaran">Kode pembayaran</p>
          <p class="value">{{ payInfo.padrymentCode }}</p>
          <!-- <p class="value">{{ payInfo.padrymentCode }}</p> -->
          <img
            v-clipboard:copy="payInfo.padrymentCode"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
            class="copy"
            src="@/assets/img/copy.png"
            alt=""
          />
        </div>
      </div>

      <div class="extra-info">
        <div class="tabs">
          <div
            :class="activate == 1 ? 'tab activate' : 'tab'"
            @click="activate = 1"
          >
            Hal yang perlu diperhatikan
          </div>
          <div
            :class="activate == 2 ? 'tab activate' : 'tab'"
            @click="activate = 2"
          >
            Cara Pembayaran
          </div>
        </div>
        <div class="tab-content" v-show="activate == 1">
          <p v-for="(item, index) in payInfo.prdrecautions.step" :key="index">
            {{ item }}
          </p>
        </div>
        <div class="tab-content" v-show="activate == 2">
          <p
            v-for="(item, index) in payInfo.redrpaymentProcess[0].step"
            :key="index"
          >
            {{ item }}
          </p>
        </div>
      </div>
      <button type="button" class="paid-btn" @click="onPaid">
        Saya sudah melakukan pembayaran
      </button>
    </div>
    <van-dialog
      v-model="visible"
      :showConfirmButton="false"
      close-on-click-overlay
      class="repayment-success-dialog"
    >
      <div class="dialog_body">
        <p class="tip">Pembayaran berhasil！</p>
        <img src="@/assets/img/success.png" alt="" />
        <button
          v-if="payInfo.padryCodeid"
          type="button"
          class="confirm-btn"
          @click="onConfirm"
        >
          ok
        </button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { Tab, Tabs, Dialog, Toast } from 'vant'
import dialogVisible from '@/mixins/dialogVisible'
import request from '@/utils/request'
import VueClipboard from 'vue-clipboard2'

Vue.use(Tab)
Vue.use(Tabs)
Vue.use(VueClipboard)

export default {
  name: 'Pay',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      payInfo: {
        prdrecautions: {},
        redrpaymentProcess: [{}]
      },
      activate: 1

    }
  },
  created () {
    this.getPayCode()
  },
  methods: {
    getPayCode () {
      const { repayType, amount, orderId, channelId, payChannel, name, payWay, payWayName } = this.$route.query
      const postData = {
        ordrderID: orderId,
        padryAmount: amount,
        padryType: repayType,
        padrymentChannel: channelId,
        padryChannel: payChannel,
        padryChannelName: name,
        padryWay: payWay,
        padryWayName: payWayName
      }
      request.post('crdreatePaymentCode', postData)
        .then(res => {
          this.payInfo = res
        })
    },
    onCopySuccess () {
      Toast('Berhasil disalin')
    },
    onCopyError () {
      Toast('Penyalinan gagal')
    },
    onPaid () {
      const { orderId } = this.$route.query
      const postData = {
        ordrderID: orderId,
        padryCodeid: this.payInfo.padryCodeid
      }
      request.post('redrpaymentStatus', postData)
        .then(res => {
          if (res.padryStatus === 2) {
            this.visible = true
          } else {
            Toast(res.padryMsg)
          }
        })
    },
    onConfirm () {
      this.onCloseDialog()
      this.$router.push('/home')
    }
  }
}
</script>

<style scoped lang="less">
.pay {
}
.content {
  padding: 40px;
}

.pay-amount {
  background: url("../assets/img/huankuan_info_bg.png") no-repeat;
  background-size: 100% 100%;
  height: 210px;
  margin-bottom: 39px;
  padding-left: 66px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .amount {
    font-size: 48px;
    font-weight: normal;
    color: #ffffff;
  }
  .label {
    font-size: 28px;
    font-weight: 500;
    color: #ffffff;
  }
}

.pay-info-list {
  margin-bottom: 39px;
  color: #fff;

  div {
    padding: 0 20px;
    height: 83px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    p {
      font-size: 30px;
      font-weight: 500;
    }
    p:nth-child(2) {
      font-weight: bold;
    }
  }
  .copy {
    width: 30px;
    height: 30px;
  }
}

.extra-info {
  padding: 30px;
  margin-bottom: 87px;

  .tabs {
    display: flex;
    justify-content: space-between;

    .tab {
      width: 275px;
      height: 94px;
      padding: 0 10px;
      margin-bottom: 20px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 34px;
      font-weight: 500;
      color: #ffffff;
      border-bottom: 8px solid rgba(0, 0, 0, 0);
    }
    .activate {
      font-weight: bold;
      opacity: 0.8;
      border-color: #27b8d7;
    }
  }
}

.tab-content {
  margin-bottom: 36px;
  overflow-y: scroll;

  p {
    font-size: 29px;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    word-wrap: break-word;

    &:nth-child(n + 2) {
      margin-top: 16px;
    }
  }
}

.paid-btn {
  .submit-btn;
  width: 690px;
  margin-top: 24px;
  margin-bottom: 30px;
  font-size: 32px;
}

.repayment-success-dialog {
  width: 564px;
  height: 600px;

  .dialog_body {
    height: 602px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .tip {
    font-size: 40px;
    font-weight: 800;
    color: #ffffff;
    text-shadow: 0px 5px 5px #dd470f;
  }

  img {
    width: 250px;
    height: 260px;
    margin: 30px;
  }

  .confirm-btn {
    .submit-btn;
    width: 464px;
  }
}
</style>
